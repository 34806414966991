import requests from "./httpService";

const CardiometricsAccessTokenService = {
  getAccessToken() {
    return requests.get("/cardiometricsAccessToken");
  },
  getAccessTokenByUserId(userId) {
    return requests.get(`/cardiometricsAccessToken/user/${userId}`);
  },
  getAccessTokenByCode(code) {
    return requests.get(`/cardiometricsAccessToken/genToken/${code}`);
  },
  delete(id) {
    return requests.delete(`/cardiometricsAccessToken/${id}`);
  },
};

export default CardiometricsAccessTokenService;
