import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { AdminContext } from "../../context/AdminContext";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { notifySuccess, notifyError } from "../../utils/toast";
import Cookies from "js-cookie";
import CardiometricsAccessTokenService from "../../services/CardiometricsAccessTokenService";

import { useQuery } from "../../hooks/useQusery";

const PrivateRoute = ({ children, ...rest }) => {
  const query = useQuery();
  const history = useHistory();

  const { state, dispatch } = useContext(AdminContext);

  const { adminInfo } = state;

  React.useEffect(() => {
    // console.log(adminInfo);
    if (query.get("token")) {
      CardiometricsAccessTokenService.getAccessTokenByUserId(query.get("token"))
        .then((res) => {
          if (res) {
            notifySuccess("Login Success!");
            if (adminInfo) {
              adminInfo.cardioToken = res.access_token;
              Cookies.set("adminInfo", JSON.stringify(adminInfo));
              dispatch({
                type: "USER_LOGIN",
                payload: adminInfo,
              });
            } else {
              notifyError("Please Login First");
            }

            Cookies.set("cardioToken", JSON.stringify(res.access_token));
            history.replace("/");
          }
        })
        .catch((err) => {
          console.log(err);
          notifyError("Something went wrong");
          // history.replace("/");
        });
    }
  }, []);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        adminInfo?.token && adminInfo.cardioToken ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
