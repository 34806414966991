import React, { useState, createContext } from "react";

// create context
export const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAssignDeviceOpen, setIsAssignDeviceOpen] = useState(false);
  const [isEDFUploadModalOpen, setIsEDFUploadModalOpen] = useState(false);
  const [isAssignDeviceModalOpen, setIsAssignDeviceModalOpen] = useState(false);
  const [isUserApproveModalOpen, setIsUserApproveModalOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const [isCardioInfoModal, setIsCardioInfoModal] = useState(false);

  const [isCancelTermination, setIsCancelTermination] = useState(false);

  const closeCancelTermination = () => setIsCancelTermination(false);
  const toggleCancelTermination = () =>
    setIsCancelTermination(!isCancelTermination);

  const closeCardioInfoModal = () => setIsCardioInfoModal(false);
  const toggleCardioInfoModal = () => setIsCardioInfoModal(!isCardioInfoModal);

  const [isallocateDeviceDrawerOpen, setIsallocateDeviceDrawerOpen] =
    useState(false);
  const closeSidebar = () => setIsSidebarOpen(false);
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const closeDrawer = () => setIsDrawerOpen(false);
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  const closeModal = () => setIsModalOpen(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const closAssignDeviceDrawer = () => setIsAssignDeviceOpen(false);
  const toggleAssignDeviceDrawer = () =>
    setIsAssignDeviceOpen(!isAssignDeviceOpen);

  const closeUserApproveModalOpen = () => setIsUserApproveModalOpen(false);
  const toggleUserApproveModalOpen = () =>
    setIsUserApproveModalOpen(!isUserApproveModalOpen);

  const closeEDFUploadModalOpen = () => setIsEDFUploadModalOpen(false);
  const toggleEDFUploadModalOpen = () =>
    setIsEDFUploadModalOpen(!isEDFUploadModalOpen);
  const closeAssignDeviceModalOpen = () => setIsAssignDeviceModalOpen(false);
  const toggleAssignDeviceModalOpen = () =>
    setIsAssignDeviceModalOpen(!isAssignDeviceModalOpen);

  const closeAllocateDeviceDrawerOpen = () =>
    setIsallocateDeviceDrawerOpen(false);
  const toggleAllocateDeviceDrawerOpen = () =>
    setIsallocateDeviceDrawerOpen(!isAssignDeviceOpen);

  return (
    <SidebarContext.Provider
      value={{
        isSidebarOpen,
        toggleSidebar,
        closeSidebar,
        isDrawerOpen,
        toggleDrawer,
        closeDrawer,
        setIsDrawerOpen,
        isModalOpen,
        toggleModal,
        closeModal,
        isUpdate,
        setIsUpdate,
        isAssignDeviceOpen,
        toggleAssignDeviceDrawer,
        closAssignDeviceDrawer,
        isEDFUploadModalOpen,
        toggleEDFUploadModalOpen,
        closeEDFUploadModalOpen,

        isallocateDeviceDrawerOpen,
        closeAllocateDeviceDrawerOpen,
        toggleAllocateDeviceDrawerOpen,

        isUserApproveModalOpen,
        toggleUserApproveModalOpen,
        closeUserApproveModalOpen,
        isAssignDeviceModalOpen,
        toggleAssignDeviceModalOpen,
        closeAssignDeviceModalOpen,
        isCardioInfoModal,
        toggleCardioInfoModal,
        closeCardioInfoModal,
        isCancelTermination,
        toggleCancelTermination,
        closeCancelTermination,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
